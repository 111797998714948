import { Icon, Stack } from "~/components/vendorUI";
import { Nav } from "@capterra/vendor-ui-components-nav";
import { css } from "ui/css";
import {
  Link,
  useLocation,
  useLoaderData,
  useNavigate,
  useFetcher,
} from "@remix-run/react";
import { useState, type ReactNode } from "react";
import { useTranslation, useScript } from "~/utils";
import type { loader } from "app/routes/__vendor-portal";
import getEnv from "~/utils/env";
import type {
  DropdownLinkTypeOutput,
  LinkTypeOutput,
} from "app/types/navigation";
import { useCurrentUser } from "app/hooks/useCurrentUser";

export const NavBar = () => {
  const { session } = useLoaderData<typeof loader>();

  if (!("userId" in session && session.isLoggedIn)) return <></>;

  return (
    <>
      <MainNav />
      <SideNav />
    </>
  );
};

type NavbarLinkType = LinkTypeOutput & { dataTracking?: string } & (
    | { type: "subItem" | "link" }
    | { type: "trigger"; childLinks: string[] }
  );

const NavbarLink = ({
  external,
  name,
  path,
  dataTracking,
  ...rest
}: NavbarLinkType) => {
  const location = useLocation();

  const CompLink = {
    subItem: Nav.SubItem,
    trigger: Nav.Trigger,
    link: Nav.Link,
  }[rest.type];

  const isLinkActive =
    rest.type !== "trigger"
      ? location.pathname === path
      : [path, ...rest.childLinks].includes(location.pathname);

  return (
    <CompLink active={isLinkActive} asChild>
      {external ? (
        <a
          href={path}
          target="_blank"
          rel="noopener noreferrer"
          data-tracking={dataTracking}
          data-gtm={dataTracking}
        >
          {name}
        </a>
      ) : (
        <Link to={path} data-tracking={dataTracking} data-gtm={dataTracking}>
          {name}
        </Link>
      )}
    </CompLink>
  );
};

const getTrackingId = (subItemName: string) => {
  switch (subItemName) {
    case "Account FAQs":
      return "account-faq";

    case "Manage Badges":
      return "account-manage-badges";

    case "Manage Campaigns":
      return "account-manage-campaigns";

    case "Manage Users":
      return "account-manage-users";

    case "PPL Billing History":
      return "account-ppl-billing-history";

    case "PPL Payment Information":
      return "account-ppl-payment-info";

    case "PPC Billing History":
      return "account-ppc-billing-history";

    case "PPC Payment Information":
      return "account-ppc-payment-info";

    case "PPC Service Description":
      return "account-ppc-legal";

    default:
      return "";
  }
};

const DropdownNavGroup = ({ subItem }: { subItem: DropdownLinkTypeOutput }) => {
  const { session } = useLoaderData<typeof loader>();
  const { t } = useTranslation();

  if (!("links" in subItem)) return <></>;
  return (
    <Nav.Group>
      {subItem.groupTitle && <Nav.Title>{subItem.groupTitle}</Nav.Title>}
      {subItem.links.map((subItem) => (
        <NavbarLink
          key={subItem.name}
          {...subItem}
          type="subItem"
          dataTracking={getTrackingId(subItem.name)}
        />
      ))}
      {subItem.groupTitle === t("NAV_LABEL_ADMIN") &&
        getEnv().REACT_APP_ENV?.toLowerCase() !== "production" &&
        "userId" in session &&
        session.employeeId && (
          <Stack align="stretch" gap="8px">
            <TranslationMode />
            <ContentfulMode />
          </Stack>
        )}
    </Nav.Group>
  );
};

type DropdownNavType = {
  subNav: DropdownLinkTypeOutput[];
  children?: ReactNode;
};

const DropdownNav = ({ subNav, children = <></> }: DropdownNavType) => (
  <Nav.Content>
    <Nav.SubList>
      {subNav.map((subItem, idx) =>
        "links" in subItem ? (
          <DropdownNavGroup
            key={`SubItemGroup-${idx}-${subItem.links.length}`}
            subItem={subItem}
          />
        ) : (
          <NavbarLink key={subItem.name} {...subItem} type="subItem" />
        ),
      )}
      {children}
    </Nav.SubList>
  </Nav.Content>
);

const MainNav = () => {
  const { session } = useLoaderData<typeof loader>();
  if (!("userId" in session && session.isLoggedIn)) return <></>;

  return (
    <Nav.Root>
      <Nav.List>
        {session.navConfig.navbar.map((navItem, idx) => (
          <Nav.Item key={`${navItem.name}-${idx}`}>
            {Array.isArray(navItem.subNav) ? (
              <Nav.Popover toggleOn="hover">
                <NavbarLink
                  {...navItem}
                  type="trigger"
                  childLinks={navItem.subNav.flatMap((subItem) =>
                    "links" in subItem
                      ? subItem.links.map(({ path }) => path)
                      : subItem.path,
                  )}
                />
                <DropdownNav subNav={navItem.subNav} />
              </Nav.Popover>
            ) : (
              <NavbarLink {...navItem} type="link" />
            )}
          </Nav.Item>
        ))}
      </Nav.List>
    </Nav.Root>
  );
};

const SideNav = () => {
  const { t } = useTranslation();
  const { session } = useLoaderData<typeof loader>();

  if (!("userId" in session && session.isLoggedIn)) return <></>;

  const USER_INITIALS =
    `${session.profile?.firstName?.at(0) || ""}${session.profile?.lastName?.at(0) || ""}`.toUpperCase();

  return (
    <Nav.Root className={css({ marginInlineStart: "auto" })}>
      <Nav.List className={css({ gap: "0px !" })}>
        <Nav.Item>
          <Nav.Link isIcon asChild>
            <Link to="/help-center" data-tracking="header_help_center_link">
              <Icon
                name="LightBulb"
                color="white"
                data-tracking="header_help_center_link"
              />
            </Link>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Popover toggleOn="hover">
            <Nav.Trigger isIcon asChild>
              <Link
                to="/"
                data-tracking="account-menu-open"
                data-gtm="account-menu-open"
              >
                {session.profile?.firstName ? (
                  USER_INITIALS
                ) : (
                  <Icon name="Profile" color="white" />
                )}
              </Link>
            </Nav.Trigger>
            <DropdownNav subNav={session.navConfig.sideNav}>
              <Nav.Divider />
              <Nav.SubItem asChild>
                <Link
                  to="/logout"
                  data-tracking="account-logout"
                  data-gtm="account-logout"
                >
                  {t("ACTION_LOGOUT")}
                </Link>
              </Nav.SubItem>
            </DropdownNav>
          </Nav.Popover>
        </Nav.Item>
      </Nav.List>
    </Nav.Root>
  );
};

const TranslationModeScript = () => {
  useScript("//cdn.crowdin.com/jipt/jipt.js");
  return null;
};

const ContentfulMode = () => {
  const { isContentfulPreviewMode } = useCurrentUser();
  const previewModeFetcher = useFetcher({
    key: "contentful-preview-mode",
  });
  const navigate = useNavigate();

  const toggle = () => {
    previewModeFetcher.submit(
      {},
      {
        action: "/api/help-center/preview",
        method: "POST",
        encType: "application/json",
      },
    );

    // Reload the page
    navigate(".", { replace: true });
  };

  return (
    <Nav.SubItem asChild>
      <Link onClick={toggle} to="#">
        {isContentfulPreviewMode
          ? "Switch to Live Mode"
          : "Switch to Preview Mode"}
      </Link>
    </Nav.SubItem>
  );
};

const TranslationMode = () => {
  const [translationMode, setTranslationMode] = useState(false);
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const handleTranslationMode = () => {
    if (!translationMode) {
      setTranslationMode(true);
      i18n.changeLanguage("tr-TR");
    } else {
      navigate(window.location.pathname, { replace: true });
    }
  };

  return (
    <>
      <Nav.SubItem asChild>
        <Link onClick={handleTranslationMode} to="#">
          {translationMode ? "Stop" : "Start"} Translation Mode
        </Link>
      </Nav.SubItem>
      {translationMode && <TranslationModeScript />}
    </>
  );
};
